import { observable, action } from 'mobx';
import { getQueryParam } from '@/utils/app';

const DEFAULT_LANG = process.env.DEFAULT_LANG || 'zh';

const { dataset } = document.getElementById('root');
const hasLocale = locale => ['zh', 'en', 'zh-Hant-HK'].includes(locale);
class App {
  @observable lang = hasLocale(dataset.locale) ? dataset.locale : DEFAULT_LANG;
  serverValue = {};

  constructor() {
    this.serverValue = {
      token: dataset.cozyToken,
      cozyDomain: dataset.cozyDomain,
      csrf: dataset.loginCsrf,
      redirect: dataset.loginRedirect,
      expired: dataset.loginExpired,
      qrUrl: dataset.qrUrl,
      code: dataset.qrCode,
      bind: dataset.qrBind,
      appId: dataset.appId,
      timestamp: dataset.timeStamp,
      nonceStr: dataset.nonceStr,
      signature: dataset.signature,
    };
    const urlParam = getQueryParam();
    this.redirect = urlParam.id ? '/extract' : '/';
    this.urlParam = {
      app: urlParam.app || '',
    };
    this.setFromHash();
  }

  setFromHash = () => {
    // 保存其他App的前端路由
    if (!['', '#/', '#/extract', '#/privacy', '#/terms'].includes(window.location.hash)) {
      window.sessionStorage.setItem(
        'from-hash',
        JSON.stringify({
          url: window.location.href.split('#')[0],
          hash: window.location.hash,
        })
      );
    }
  };

  /* eslint-disable class-methods-use-this */
  get fromHash() {
    try {
      const fromHash = window.sessionStorage.getItem('from-hash');
      if (fromHash) {
        const { url, hash } = JSON.parse(fromHash);
        if (window.location.href.split('#')[0] === url) {
          return hash;
        }
      }
      return '';
    } catch (error) {
      console.error('cannot parse from-hash in sessionStorage', error);
      return '';
    }
  }

  @action
  switchLang = locale => {
    if (!hasLocale(locale)) {
      throw new Error(`unknown locale ${locale}`);
    }
    this.lang = locale;
  };
}

export default new App();
